.root {
  @apply fixed top-0  z-40 transition-all duration-150 w-full;
}

.link {
  @apply inline-flex items-center px-2 py-2 leading-5 text-base font-medium  ease-in-out duration-75 cursor-pointer text-zinc-200 rounded-md hover:bg-black hover:bg-opacity-25 hover:scale-[1.01] transition-all ;
}

.link:hover {
  @apply text-zinc-100;
}

.link:focus {
  @apply outline-none text-zinc-100 ring-2 ring-pink-500 ring-opacity-50 ring-0;
}

.logo {
  @apply cursor-pointer rounded-full transform duration-100 ease-in-out ring-0;
}

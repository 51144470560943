@tailwind base;
@tailwind components;
@tailwind utilities;



*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  @apply outline-none ring-2 ring-primary ring-opacity-50;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope/Manrope-Medium.ttf");
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
}

html,
body {
  font-family: "Manrope", -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif;
  textRendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  @apply text-white bg-black antialiased;
  background: #000000; /* THIS IS NEEDED FOR /embed/ */
}
/* Reset text area styles */
textarea{
  border: 0;
  outline: 0;
  resize: none;
  padding: 0;
  margin: 0;
}
body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.height-screen-helper {
  height: calc(100vh - 80px);
}

.box-shadow {
  -webkit-box-shadow: 0px 40px 75px rgba(105, 42, 215, .3);
  box-shadow: 0px 40px 75px rgba(105, 42, 215, .3);
}
.box-shadow-soft {
  -webkit-box-shadow: 0px 40px 75px rgba(105, 42, 215, .1);
  box-shadow: 0px 40px 75px rgba(105, 42, 215, .1);
}

.box-shadow-soft-geo {
  -webkit-box-shadow: 0px 20px 50px rgba(215, 42, 88, 0.25);
  box-shadow: 0px 20px 50px rgba(215, 42, 88, 0.25);
}


pre[class*='language-'] {
  color: theme('colors.slate.50');
}

.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.selector.class,
.token.function {
  color: theme('colors.pink.400');
}


.token.attr-name,
.token.keyword,
.token.rule,
.token.pseudo-class,
.token.important {
  color: theme('colors.slate.300');
}

.token.module {
  color: theme('colors.pink.400');
}

.token.attr-value,
.token.class,
.token.string,
.token.property {
  color: theme('colors.sky.300');
}

.token.punctuation,
.token.attr-equals {
  color: theme('colors.slate.500');
}

.token.unit,
.language-css .token.function {
  color: theme('colors.teal.200');
}

.token.comment,
.token.operator,
.token.combinator {
  color: theme('colors.slate.400');
}







.h1 {
  @apply text-3xl font-bold;
}

.h2 {
  @apply text-3xl font-bold;
}

.h3 {
  @apply text-3xl font-bold;
}

.h4 {
  @apply text-2xl font-bold;
}

@screen md {
  .h1 {
      @apply text-7xl;
  }

  .h2 {
      @apply text-6xl;
  }
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply ring-0;
}

/* Hamburger button */
.hamburger svg>*:nth-child(1),
.hamburger svg>*:nth-child(2),
.hamburger svg>*:nth-child(3) {
    transform-origin: center;
    transform: rotate(0deg);
}

.hamburger svg>*:nth-child(1) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg>*:nth-child(2) {
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg>*:nth-child(3) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
}

.hamburger.active svg>*:nth-child(1) {
    opacity: 0;
    y: 11;
    transform: rotate(225deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg>*:nth-child(2) {
    transform: rotate(225deg);
    transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg>*:nth-child(3) {
    y: 11;
    transform: rotate(135deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
}

/* Custom Swiper styles */
.swiper-button-disabled {
    @apply opacity-50 cursor-default pointer-events-none;
}

/* Pulsing animation */
@keyframes pulseLoop {
    0% {
        opacity: 0;
        transform: scale(1) translateZ(0);
    }

    30% {
        opacity: .4;
    }

    60% {
        opacity: 0;
    }

    80% {
        opacity: 0;
        transform: scale(2.5) translateZ(0);
    }
}

.pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 8000ms linear infinite;
}

.pulse-1 {
    animation-delay: -2000ms;
}

.pulse-2 {
    animation-delay: -4000ms;
}

.pulse-3 {
    animation-delay: -6000ms;
}

.translate-z-0 {
    transform: translateZ(0);
}

/* Custom AOS distance */
@media screen {
    html:not(.no-js) [data-aos=fade-up] {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down] {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    html:not(.no-js) [data-aos=fade-right] {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-left] {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-up-right] {
        -webkit-transform: translate3d(-10px, 10px, 0);
        transform: translate3d(-10px, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-up-left] {
        -webkit-transform: translate3d(10px, 10px, 0);
        transform: translate3d(10px, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-right] {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-left] {
        -webkit-transform: translate3d(10px, -10px, 0);
        transform: translate3d(10px, -10px, 0);
    }

    html:not(.no-js) [data-aos=zoom-in-up] {
        -webkit-transform: translate3d(0, 10px, 0) scale(.6);
        transform: translate3d(0, 10px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-down] {
        -webkit-transform: translate3d(0, -10px, 0) scale(.6);
        transform: translate3d(0, -10px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-right] {
        -webkit-transform: translate3d(-10px, 0, 0) scale(.6);
        transform: translate3d(-10px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-left] {
        -webkit-transform: translate3d(10px, 0, 0) scale(.6);
        transform: translate3d(10px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-out-up] {
        -webkit-transform: translate3d(0, 10px, 0) scale(1.2);
        transform: translate3d(0, 10px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-down] {
        -webkit-transform: translate3d(0, -10px, 0) scale(1.2);
        transform: translate3d(0, -10px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-right] {
        -webkit-transform: translate3d(-10px, 0, 0) scale(1.2);
        transform: translate3d(-10px, 0, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-left] {
        -webkit-transform: translate3d(10px, 0, 0) scale(1.2);
        transform: translate3d(10px, 0, 0) scale(1.2);
    }
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* gradient */
.yt-lite::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}


.loading-box::before{
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    background-image: conic-gradient(transparent, transparent, transparent, #00ccff);
    animation: loadingbox 4s linear infinite;

}
.loading-box::after{
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background-image: conic-gradient(transparent, transparent, transparent, #d400d4);
  animation: loadingbox 4s linear infinite;
  animation-delay: -2s;
}

.loading-box span{
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: #0c1022;
  z-index: 1;
}


.loading-box2::before{
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background-image: conic-gradient(transparent, transparent, transparent, #c3b6fc);
  animation: loadingbox 4s linear infinite;

}
.loading-box2::after{
content: "";
position: absolute;
width: 150%;
height: 150%;
background-image: conic-gradient(transparent, transparent, transparent, #c3b6fc);
animation: loadingbox 4s linear infinite;
}

.loading-box2.spananim{
position: absolute;
inset: 3px;
border-radius: 16px;
background: #0c1022;
z-index: 1;
}



@keyframes loadingbox {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
  
}

@layer base {
  :root {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dialog-overlay[data-state='open'],
.dialog-content[data-state='open'] {
  animation: fadeIn 300ms ease-out;
}

.dialog-overlay[data-state='closed'],
.dialog-content[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply ring-0;
}

/* Hamburger button */
.hamburger svg>*:nth-child(1),
.hamburger svg>*:nth-child(2),
.hamburger svg>*:nth-child(3) {
    transform-origin: center;
    transform: rotate(0deg);
}

.hamburger svg>*:nth-child(1) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg>*:nth-child(2) {
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg>*:nth-child(3) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
}

.hamburger.active svg>*:nth-child(1) {
    opacity: 0;
    y: 11;
    transform: rotate(225deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg>*:nth-child(2) {
    transform: rotate(225deg);
    transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg>*:nth-child(3) {
    y: 11;
    transform: rotate(135deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
}

/* Custom Swiper styles */
.swiper-button-disabled {
    @apply opacity-50 cursor-default pointer-events-none;
}

.testimonials-carousel .swiper-slide {
    @apply opacity-80;
}

.testimonials-carousel .swiper-slide.swiper-slide-active {
    @apply opacity-100;
}

/* Pulsing animation */
@keyframes pulseLoop {
    0% {
        opacity: 0;
        transform: scale(.25) translateZ(0);
    }

    30% {
        opacity: .4;
    }

    70% {
        opacity: 0;
    }

    80% {
        transform: scale(1) translateZ(0);
    }
}

.pulse {
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 12000ms linear infinite;
}

.pulse-1 {
    animation-delay: -4000ms;
}

.pulse-2 {
    animation-delay: -8000ms;
}

.translate-z-0 {
    transform: translateZ(0);
}

/* Custom AOS distance */
@media screen {
    html:not(.no-js) [data-aos=fade-up] {
        -webkit-transform: translate3d(0, 14px, 0);
        transform: translate3d(0, 14px, 0);
    }

    html:not(.no-js) [data-aos=fade-down] {
        -webkit-transform: translate3d(0, -14px, 0);
        transform: translate3d(0, -14px, 0);
    }

    html:not(.no-js) [data-aos=fade-right] {
        -webkit-transform: translate3d(-14px, 0, 0);
        transform: translate3d(-14px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-left] {
        -webkit-transform: translate3d(14px, 0, 0);
        transform: translate3d(14px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-up-right] {
        -webkit-transform: translate3d(-14px, 14px, 0);
        transform: translate3d(-14px, 14px, 0);
    }

    html:not(.no-js) [data-aos=fade-up-left] {
        -webkit-transform: translate3d(14px, 14px, 0);
        transform: translate3d(14px, 14px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-right] {
        -webkit-transform: translate3d(-14px, -14px, 0);
        transform: translate3d(-14px, -14px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-left] {
        -webkit-transform: translate3d(14px, -14px, 0);
        transform: translate3d(14px, -14px, 0);
    }

    html:not(.no-js) [data-aos=zoom-in-up] {
        -webkit-transform: translate3d(0, 14px, 0) scale(.6);
        transform: translate3d(0, 14px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-down] {
        -webkit-transform: translate3d(0, -14px, 0) scale(.6);
        transform: translate3d(0, -14px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-right] {
        -webkit-transform: translate3d(-14px, 0, 0) scale(.6);
        transform: translate3d(-14px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-left] {
        -webkit-transform: translate3d(14px, 0, 0) scale(.6);
        transform: translate3d(14px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-out-up] {
        -webkit-transform: translate3d(0, 14px, 0) scale(1.2);
        transform: translate3d(0, 14px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-down] {
        -webkit-transform: translate3d(0, -14px, 0) scale(1.2);
        transform: translate3d(0, -14px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-right] {
        -webkit-transform: translate3d(-14px, 0, 0) scale(1.2);
        transform: translate3d(-14px, 0, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-left] {
        -webkit-transform: translate3d(14px, 0, 0) scale(1.2);
        transform: translate3d(14px, 0, 0) scale(1.2);
    }
}

/* Typography */
.h1 {
  @apply text-4xl font-extrabold;
}

.h2 {
  @apply text-4xl font-extrabold;
}

.h3 {
  @apply text-3xl font-extrabold;
}

.h4 {
  @apply text-2xl font-extrabold;
}

@screen md {
  .h1 {
      @apply text-6xl;
  }

  .h2 {
      @apply text-5xl;
  }

  .h3 {
      @apply text-4xl;
  }
}

/* Buttons */
.btn,
.btn-sm {
  @apply text-sm font-medium inline-flex items-center justify-center border border-transparent rounded-full transition duration-150 ease-in-out;
}

.btn {
  @apply  text-base px-4 py-1.5;
}

.btn-sm {
  @apply px-3 py-1;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
-webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-slate-800 border border-transparent focus:border-purple-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply text-slate-200 text-sm px-3 py-1.5;
}

.form-input,
.form-textarea {
  @apply placeholder-slate-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-purple-600 rounded-sm;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
display: none;
}

.no-scrollbar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeOut {
  animation: fadeOut 1s ease-in-out forwards;
}

.fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

.markdown > * {
  all: revert;
}




.starssection{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: animateBg 50s linear infinite;
}
@keyframes animateBg {
  0%,100%
  {
      transform:scale(1);
  }
  50%
  {
      transform:scale(1.2);
  }
}
.starsspan {
  position: absolute;
  width: 0px;
  height: 0px;
  bottom: 0;
  left: 0;
  background: #fff;
  animation:  animate 3s linear infinite;
}

.starsspan::before {
  content: '';
  position: absolute;
  opacity: 35%;
  top: 0;
  width: 150px;
  height: 1px;
  background: linear-gradient(270deg, #fff, transparent);
}

@keyframes animate {
  0%
  {
      transform: rotate(-315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;

  }
  100%
  {
      transform: rotate(-315deg) translateX(1500px);
      opacity: 0;

  }
  
}
.starsspan:nth-child(1){
  top: 0;
  left: 0;
  left:initial;
  animation-delay:0 ;
  animation-duration: 1.5s;
}

/* .starsspan:nth-child(2){
  top: 0;
  left: 80px;
  right:initial;
  animation-delay:0.2s;
  animation-duration: 3s;
} */

.starsspan:nth-child(3){
  top: 80px;
  left: 0px;
  right:initial;
  animation-delay:0.4s ;
  animation-duration: 2s;
}

/* .starsspan:nth-child(4){
  top: 0;
  left: 180px;
  right:initial;
  animation-delay:0.6s;
  animation-duration: 1.5s;
} */

.starsspan:nth-child(5){
  top: 0;
  left: 400px;
  right:initial;
  animation-delay:0.8s;
  animation-duration: 2.5s;
}

/* .starsspan:nth-child(6){
  top: 0;
  left: 600px;
  right:initial;
  animation-delay:1s ;
  animation-duration: 3s;
} */
.starsspan:nth-child(7 ){
  top: 300px;
  left: 0px;
  right:initial;
  animation-delay:1s ;
  animation-duration: 1.75s;
}

.starsspan:nth-child(8){
  top: 0px;
  left: 700px;
  right:initial;
  animation-delay:1.4s ;
  animation-duration: 1.25s;
}

.starsspan:nth-child(9){
  top: 0px;
  left: 1000px;
  right:initial;
  animation-delay:0.75s ;
  animation-duration: 2.25s;
}

.starsspan:nth-child(10){
  top: 0px;
  left: 1000px;
  right:initial;
  animation-delay:2.75s ;
  animation-duration: 2.25s;
}


.blinking-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 50%;
  animation: blink 15s ease-in-out infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.swiper-container > .swiper-wrapper{
  transition-timing-function : linear;
}

.chunkscroll{
scrollbar-face-color: #fff;  
scrollbar-shadow-color: #2D2C4D;  
scrollbar-highlight-color:#7D7E94;  
scrollbar-3dlight-color: #7D7E94;  
scrollbar-darkshadow-color: #2D2C4D;  
scrollbar-track-color: #7D7E94;  
scrollbar-arrow-color: #C1C1D1; 
}

.dotted{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%231F2937FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 11px;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
  height: 5px; /* Height of the scrollbar */
}

/* Handle the scrollbar's track */
::-webkit-scrollbar-track {
  background: #ffffff11; /* Make the track transparent */
}

/* Handle the scrollbar's thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 6px; /* Optional: for rounded corners */
}

/* Handle the hover state of the scrollbar's thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}



.lpath {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 4s ease-in infinite;
}
@keyframes draw {
  0% {
    stroke-dashoffset: 1000;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

.react-flow__edge{
  background: transparent !important
}

.react-flow__edge-textbg{
  fill: transparent !important
}

.react-flow__edge-text{
  fill: #fff !important;

}
.react-flow__node{
  background: #1F1D2B !important;
  border: 1px solid rgb(71, 66, 98) !important;
  border-radius: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

.react-flow__node{
  color: #fff !important;
}

ol.toaster.group.mt-10.top-10{
  bottom: 20px !important;
  top: auto !important;
}